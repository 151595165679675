import { useContext, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import AdminService, {
  IUser,
  IOrganisation,
} from "../../../services/admin/admin.service";
import { commaSeparated, hasSufficientPrivilege } from "../../../helpers";
import { AuthContext } from "../../../contexts";
import {
  Editable,
  GoogleMapsLink,
  RecordMissing,
} from "../../../components/content";
import { Header, Section } from "../../../components/layout";
import {
  CircularProgress,
  Icon,
  Link,
  TableRow,
  TableCell,
  TableRowSubhead,
  Popover,
  Button,
} from "../../../components/util";
import {
  firstNameSchema,
  lastNameSchema,
  phoneNumberSchema,
} from "../../../validations";
import { fieldRequiredSchema } from "../../../validations/fields.schemas";
import { UserOrgModal, tOrgModalType } from "./UserOrgModal";
import userService from "../../../services/user/user.service";
import * as Styled from "./styles.css";
import { Role } from "../../../types/user.type";

const General = () => {
  const auth = useContext(AuthContext);
  const { id } = useParams<IParams>();
  const queryClient = useQueryClient();
  const userFetchCmd: string = "admin-user-details";
  const {
    isLoading,
    isError,
    data: user,
  } = useQuery<IUser | null | undefined>([userFetchCmd, { id }], async () =>
    userService.getUserByID(id),
  );

  if (isLoading) {
    return <CircularProgress contained />;
  } else if (isError || user == null) {
    return (
      <RecordMissing
        icon="person"
        message="Please check the user id or navigate back to Users."
        type="user"
        back={{
          title: "Back to Users",
          path: "/users",
        }}
      />
    );
  }

  const iAuditorId = (
    user.externalMappings?.find(
      (em: any) => em.name === "iauditor_integration",
    ) as any
  )?.related_id;

  const userName = commaSeparated([user.lastname, user.firstname]);

  return (
    <Styled.Grid>
      <Header title="User" type="section" />
      <Styled.Table
        columns={[
          {
            title: "Key",
            expand: true,
          },
          { title: "Value", expand: true },
        ]}
        orientation="horizontal"
      >
        <TableRowSubhead>User details</TableRowSubhead>
        <TableRow>
          <TableCell>First name</TableCell>
          <TableCell>
            <Editable
              field="firstname"
              fieldValue={user.firstname}
              hasPermission={hasSufficientPrivilege(
                Role.Admin,
                auth.user?.roles || [],
                user.roles,
              )}
              record={user}
              recordType="User"
              onSubmitService={AdminService.updateUser}
              onSubmitRefresh={() =>
                queryClient.invalidateQueries("admin-user-details")
              }
              validationSchema={firstNameSchema}
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Last name</TableCell>
          <TableCell>
            <Editable
              field="lastname"
              fieldValue={user.lastname}
              hasPermission={hasSufficientPrivilege(
                Role.Admin,
                auth.user?.roles || [],
                user.roles,
              )}
              record={user}
              recordType="User"
              onSubmitService={AdminService.updateUser}
              onSubmitRefresh={() =>
                queryClient.invalidateQueries("admin-user-details")
              }
              validationSchema={lastNameSchema}
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Role</TableCell>
          <TableCell>
            <Editable
              field="roles"
              fieldValue={user.roles && user.roles[0]}
              userRole
              hasPermission={hasSufficientPrivilege(
                Role.Admin,
                auth.user?.roles || [],
                user.roles,
              )}
              record={user}
              recordType={`User role`}
              onSubmitService={async () => {
                /* This is not used at all for the userRole component*/
              }}
              onSubmitRefresh={() =>
                queryClient.invalidateQueries("admin-user-details")
              }
              validationSchema={fieldRequiredSchema("Role")}
            />
          </TableCell>
        </TableRow>

        <TableRowSubhead>Contact details</TableRowSubhead>
        <TableRow>
          <TableCell>Email</TableCell>
          <TableCell>
            <Link href={`mailto:${user.email}`}>{user.email}</Link>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Phone number</TableCell>
          <TableCell>
            <Editable
              field="phone"
              fieldValue={<Link href={`tel:${user.phone}`}>{user.phone}</Link>}
              hasPermission={hasSufficientPrivilege(
                Role.Admin,
                auth.user?.roles || [],
                user.roles,
              )}
              onSubmitService={AdminService.updateUser}
              onSubmitRefresh={() =>
                queryClient.invalidateQueries("admin-user-details")
              }
              record={user}
              recordType="User"
              validationSchema={phoneNumberSchema}
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <Styled.AddressCell>Address</Styled.AddressCell>
          <Styled.AddressCell>
            <Editable
              address
              fieldValue={
                <GoogleMapsLink
                  address={commaSeparated([
                    user.address_1,
                    user.address_2,
                    user.city,
                    user.state,
                    user.post_code,
                    user.country,
                  ])}
                />
              }
              field="address"
              hasPermission={hasSufficientPrivilege(
                Role.Admin,
                auth.user?.roles || [],
                user.roles,
              )}
              onSubmitService={AdminService.updateUser}
              onSubmitRefresh={() =>
                queryClient.invalidateQueries("admin-user-details")
              }
              record={user}
              recordType="User"
              validationSchema={firstNameSchema}
            />
          </Styled.AddressCell>
        </TableRow>
      </Styled.Table>

      <Section>
        <Header title="Connections" type="section" />
        <Styled.Table
          columns={[
            { title: "Key", expand: true },
            { title: "Value", expand: true },
          ]}
        >
          <TableRow>
            <TableCell>SafetyCulture ID</TableCell>
            <TableCell>{iAuditorId}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>CRM ID</TableCell>
            <TableCell>{user.crm_id}</TableCell>
          </TableRow>
        </Styled.Table>
      </Section>

      <Organisations
        data={{
          user_id: user?.id,
          organisations: user.organisations,
          refetchQuery: userFetchCmd,
        }}
        userName={userName}
      />
    </Styled.Grid>
  );
};

function Organisations(props: IOrganisationsProps) {
  const queryClient = useQueryClient();
  const { data, userName } = props;
  const [modalType, setModalType] = useState<tOrgModalType>();
  const [isModalVisible, setModalVisible] = useState<boolean>(false);
  const toggleModal = () => {
    setModalVisible(!isModalVisible);
    return;
  };

  const OverflowMenuButtonOrganisation = () => {
    return (
      <>
        <Popover
          alignment="end"
          trigger={
            <Styled.OrgMoreOptionIconButton icon="kebab-horizontal"></Styled.OrgMoreOptionIconButton>
          }
        >
          <Styled.MoreOptionSelectMenu>
            <Styled.MoreOptionOrgSectionSelectOption>
              <Styled.MoreOptionButton
                variant="unstyled"
                type="button"
                onClick={() => {
                  setModalType("Update");
                  setModalVisible(!isModalVisible);
                  return;
                }}
              >
                Update
              </Styled.MoreOptionButton>
            </Styled.MoreOptionOrgSectionSelectOption>

            <Styled.MoreOptionOrgSectionSelectOption>
              <Styled.MoreOptionButton
                variant="unstyled"
                type="button"
                onClick={() => {
                  setModalType("Remove");
                  setModalVisible(!isModalVisible);
                  return;
                }}
              >
                Remove
              </Styled.MoreOptionButton>
            </Styled.MoreOptionOrgSectionSelectOption>
          </Styled.MoreOptionSelectMenu>
        </Popover>
      </>
    );
  };

  const ActionButton = (
    <>
      <Button
        onClick={() => {
          setModalType("Add");
          setModalVisible(!isModalVisible);
          return;
        }}
      >
        <Styled.AddOrgIcon>Add organisation </Styled.AddOrgIcon>
        <Icon icon="plus" />
      </Button>
      {isModalVisible && (
        <UserOrgModal
          data={{ user_id: data.user_id }}
          onClose={toggleModal}
          onConfirm={() => queryClient.invalidateQueries(data.refetchQuery)}
          modalType={modalType}
        />
      )}
    </>
  );

  return (
    <>
      <Section>
        <Header
          title="Organisations"
          type="section"
          actions={!data.organisations?.length && ActionButton}
        />

        {!data?.organisations?.length ? (
          <Styled.EmptyOrgMessage>
            User does not have access to any organisations.{" "}
          </Styled.EmptyOrgMessage>
        ) : (
          <Styled.Table
            columns={[
              { title: "Organisation name", expand: true },
              { title: "Organisation contact email", expand: true },
              { title: "CRM ID (Apptivo)", expand: true },
              { title: "", collapse: true },
            ]}
          >
            {data?.organisations?.map(org => (
              <TableRow key={org.id}>
                <TableCell>
                  <Link
                    href={`/organisations/${
                      org.id
                    }?backText=${encodeURIComponent(userName)}`}
                  >
                    {org.name}
                  </Link>
                </TableCell>
                <TableCell>
                  {org.email && (
                    <Link href={`mailto:${org.email}`} isNewTab>
                      {org.email}
                    </Link>
                  )}
                </TableCell>
                <TableCell>{org.crm_id}</TableCell>
                <TableCell>
                  <OverflowMenuButtonOrganisation />
                  {isModalVisible && (
                    <UserOrgModal
                      data={{ user_id: data.user_id, org_id: org.id }}
                      onClose={toggleModal}
                      onConfirm={() =>
                        queryClient.invalidateQueries(data.refetchQuery)
                      }
                      modalType={modalType}
                    />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </Styled.Table>
        )}
      </Section>
    </>
  );
}

interface IParams {
  id: string; // uuid
}

interface IOrgData {
  user_id: string;
  organisations: IOrganisation[] | undefined;
  refetchQuery: string;
}

interface IOrganisationsProps {
  data: IOrgData;
  userName: string;
}

export default General;
