import { useState } from "react";
import { Header } from "../../../components/layout";
import type { IPolicy } from "../../../types";
import {
  TableCell,
  TableRow,
  TableRowSubhead,
  Typography,
} from "../../../components/util";
import PolicyService, {
  PolicySettings,
} from "../../../services/policy/policy.service";
import * as Styled from "./styles.css";
import { Spacing } from "../../../components/layout/Spacing";
import { Spinner, ToggleSwitch } from "@safetyculture/sc-web-ui";
import { usePolicyQuery } from "../../../hooks/queries/usePolicyQuery";

type Props = {
  policyId: IPolicy["id"];
  doNotInvite: boolean;
  policyStatus: string;
  effectiveToTs: string;
};

const SettingsTab = ({
  policyId,
  doNotInvite,
  policyStatus,
  effectiveToTs,
}: Props) => {
  const policy_query = usePolicyQuery(policyId);
  const [sc_invites_enabled, setScInvitesEnabled] = useState(!doNotInvite);
  const [isFetching, setIsFetching] = useState(false);

  /* If the policy is cancelled OR has expired, we want to disable to "do not invite" toggle  */
  let isDoNotInviteDisabled =
    policyStatus.toLowerCase() === "cancelled" ||
    new Date(effectiveToTs) < new Date();

  const submitInviteSettingUpdate = async (
    invite_enabled: boolean,
  ): Promise<void> => {
    try {
      const newSettings: PolicySettings = {
        do_not_invite: !invite_enabled,
      };

      setIsFetching(true);
      await PolicyService.patchSettings(policyId, newSettings);
      setScInvitesEnabled(invite_enabled);
      setIsFetching(false);
      policy_query.refetch();
    } catch (error: any) {
      setScInvitesEnabled(!doNotInvite);
      setIsFetching(false);
      if (error?.response.data?.error) {
        throw new Error(error?.response.data?.error);
      } else {
        throw error;
      }
    }
  };

  return (
    <>
      <Header
        title="Policy Settings"
        titleIcon="gear"
        titleIconPosition="start"
        type="section"
      />

      <Styled.Table
        columns={[
          { title: "Key", expand: true },
          { title: "Value", expand: true },
        ]}
        orientation="horizontal"
      >
        <TableRowSubhead>SAFETYCULTURE</TableRowSubhead>

        <TableRow>
          <TableCell>Invitations to SafetyCulture</TableCell>

          <TableCell>
            <ToggleSwitch
              isChecked={sc_invites_enabled}
              onToggle={submitInviteSettingUpdate}
              disabled={isFetching || isDoNotInviteDisabled}
            />
            <Spacing $width={8} />
            <Typography>
              Invitations to SafetyCulture are{" "}
              <Typography
                element={"span"}
                styled={{ text: { weight: "heavy" } }}
              >
                {sc_invites_enabled ? "enabled" : "disabled"}
              </Typography>
            </Typography>
            {isFetching && <Spinner />}
          </TableCell>
        </TableRow>
      </Styled.Table>
    </>
  );
};

export default SettingsTab;
