import { useQueryClient } from "react-query";
import { IconButton } from "../../../util";
import toastEvent from "../../../util/toast/toast.event";
import { SelectUserRole } from "../..";
import AdminService from "../../../../services/admin/admin.service";
import * as Styled from "./styles.css";

interface iEditUserRolesProps {
  field: any;
  onClose: () => void;
  onSubmitRefresh: () => void;
  toggleEditing: () => void;
  record: Record<string, any>;
  recordType: string;
}

const EditUserRole = ({
  field,
  onSubmitRefresh,
  record: user,
  recordType,
  toggleEditing,
}: iEditUserRolesProps) => {
  const queryClient = useQueryClient();

  const editDataInit = {
    user_id: user.id,
    role: user.roles[0],
  };

  const setErrors = (editDetails: any): Record<string, boolean> => {
    const errors: Record<string, boolean> = {};
    for (const key in editDetails) {
      if (key === field) {
        errors[key] = true;
      } else {
        errors[key] = false;
      }
    }
    return errors;
  };

  const editErrorsInit = setErrors(editDataInit);

  const handleSubmit = async (formData: { user_id: string; role: string }) => {
    await AdminService.updateUserRole(formData);
    queryClient.invalidateQueries("admin-user-details");
  };

  return (
    <>
      <Styled.InlineForm
        inline
        onSubmit={{
          service: handleSubmit,
          onSuccess: () => {
            onSubmitRefresh();
            toastEvent.success(`${recordType} updated`);
            toggleEditing();
          },
        }}
        validationErrors={[editErrorsInit]}
        validationFields={[editDataInit]}
        submitInline
        submitAlign="right"
        submitStartDisabled
        submitText="Save"
      >
        <SelectUserRole id="role" />
      </Styled.InlineForm>
      <IconButton iconShade="weak" icon="x" onClick={() => toggleEditing()} />
    </>
  );
};

export default EditUserRole;
