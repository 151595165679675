import { useContext } from "react";
import { AuthContext } from "../../../../contexts";
import { Select } from "../../../util";
import { uuidSchema } from "../../../../validations";
import { tUserRole } from "../../../../types";
import { hasAllowedRoles, hasMorePrivilege } from "../../../../helpers";
import { Role } from "../../../../types/user.type";
import Joi from "joi";

const userRoleValidationSchema = Joi.string().required();

const roleOptions = (
  roles: tUserRole[],
  currentUserRoles: tUserRole[],
): { title: string; value: string }[] => {
  const result = roles
    .filter(role => {
      return (
        hasAllowedRoles(currentUserRoles, { allowed: [Role.SuperAdmin] }) ||
        hasMorePrivilege(currentUserRoles[0], role)
      );
    })
    .map(role => {
      return { title: role, value: role };
    });
  return result;
};

const allRoles = Object.values(Role);

const SelectUserRole = ({
  disabled,
  id,
  label,
  value,
  ...otherProps
}: iSelectUserRoleProps) => {
  const { user } = useContext(AuthContext);

  return (
    <Select
      id={id}
      disabled={disabled}
      fullWidth
      placeholder="Select user role..."
      size={label ? "lg" : undefined}
      label={label}
      validationOnChange
      validationType={userRoleValidationSchema}
      options={roleOptions(allRoles, user?.roles || [])}
      grow
      value={value}
    />
  );
};

interface iSelectUserRoleProps {
  id: string;
  label?: string;
  value?: any;
  disabled?: boolean;
}

SelectUserRole.defaultProps = {};

export default SelectUserRole;
