import axios from "../../axios";
import toastEvent from "../../components/util/toast/toast.event";
import {
  Automation,
  AutomationCreatePayload,
  AutomationEditPayload,
} from "../../types/automations.type";
import { PaginatedApiResponse } from "../../types/pagination.type";

export default {
  create: async (
    automation_payload: AutomationCreatePayload,
  ): Promise<{ created_id: string } | undefined> => {
    try {
      const response = await axios.post<{ created_id: string }>(
        "/automations",
        automation_payload,
      );

      if (response.data) {
        toastEvent.success(
          `Automation created ${
            automation_payload.enabled ? "and now active" : "in inactive state"
          }`,
        );

        return response.data;
      }
    } catch (err: any) {
      toastEvent.error("Failed to create automation.");

      if (err.response?.data?.error) {
        const { data } = err.response;

        console.error(`${data.error.title}: ${data.error.detail}`);
      }
    }
  },

  update: async (
    automation_payload: AutomationEditPayload,
  ): Promise<string | undefined> => {
    try {
      const res = await axios.put<boolean>("/automations", automation_payload);

      if (res.data) {
        toastEvent.success("Automation updated");

        return automation_payload.automation_id;
      }
    } catch (err: any) {
      toastEvent.error("Failed to update automation.");

      if (err.response?.data?.error) {
        const { data } = err.response;

        console.error(`${data.error.title}: ${data.error.detail}`);
      }
    }
  },

  getAll: async (
    page?: number,
    limit?: number,
    search?: string,
  ): Promise<PaginatedApiResponse<Automation>> => {
    const res = await axios.get("/automations", {
      params: {
        page,
        limit,
        order: "asc",
        sort: "name",
        search,
      },
    });

    return res.data;
  },

  searchAll: async function (
    search?: string,
    page?: number,
    limit?: number,
  ): Promise<PaginatedApiResponse<Automation>> {
    const res = await axios.get("/automations", {
      params: {
        page,
        limit,
        order: "asc",
        sort: "name",
        search,
      },
    });

    return res.data;
  },
};
