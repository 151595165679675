import { v4 as uuidv4 } from "uuid";
import { recordAnalyticsEvent } from "../../analytics";
import axios from "../../axios";
import toastEvent from "../../components/util/toast/toast.event";
import { tUserRole } from "../../types";
import { Activity } from "../activity/activity.service";
import {
  EmailNotFoundError,
  LoginNotAuthorizedError,
  PasswordResetNotValid,
  UserAlreadyExistsError,
  UserNotFoundError,
  PasswordConfirmationInvalidError,
} from "./auth.errors";
import { IOrganisation } from "../admin/admin.service";

const AuthService = {
  authorizeSafetyCultureAuth0(authorizationType: string, location: string) {
    this.authorize({
      location: location,
      url: process.env.REACT_APP_SAFETYCULTURE_OAUTH_GRANT_URL || "",
      client_id: process.env.REACT_APP_SAFETYCULTURE_OAUTH_CLIENT_ID || "",
      response_type: "code",
      scope: "openid",
    });
  },

  async getMittiUser(): Promise<User> {
    this.getCurrentSession();

    try {
      const response = await axios.get("/users/me");
      const user = response.data as User;

      if (!user) {
        throw new UserNotFoundError("User not found");
      }

      return user;
    } catch (error: any) {
      if (error.response) {
        switch (error.response.status) {
          case 404:
            throw new UserNotFoundError("User not found");
          default:
            throw error;
        }
      }
      throw error;
    }
  },

  async getScimUserDetails(): Promise<any> {
    const response = await axios.get("/users/scim");
    return response.data;
  },

  async signOut(): Promise<void> {
    this.removeTokens();

    let domain = process.env.REACT_APP_SAFETYCULTURE_OAUTH_GRANT_URL;
    domain = domain?.replace("/authorize", "");

    window.location.href = `${domain}/v2/logout?returnTo=${window.location.origin}/login&client_id=${process.env.REACT_APP_SAFETYCULTURE_OAUTH_CLIENT_ID}`;
  },

  /**
   *returns current logged in users tokens
   */
  getCurrentSession(): iLoginToken {
    let tokens: iLoginToken = this.getTokens();
    if (!tokens.access_token) {
      throw new Error("User not logged in");
    }
    return tokens;
  },

  async refreshAuthToken() {
    let lockStatus = this.getLockStatus();

    /**
     * Exit when the token is valid and lock status is false
     */
    if (this.validateToken() === "valid" && !lockStatus) {
      return;
    }

    /**
     * Introduces a delay of 1s till the lock is released to prevent concurrent access of the module
     * This runs till the lockStatus is updated.
     */
    if (lockStatus) {
      while (lockStatus) {
        await this.sleep(1000);
        lockStatus = this.getLockStatus();
      }
      return;
    }

    /**
     * Enter only when the token is expired $$ lockstatus is false
     */
    if (this.validateToken() === "expired" && !lockStatus) {
      try {
        localStorage.setItem(is_refreshAuthToken_locked, "true");

        const refreshToken: any = localStorage.getItem(refresh_token);

        const result: any = await axios.post("/auth/refresh", {
          type: "refresh_login_token",
          refresh_token: refreshToken,
        });
        this.storeTokens(result.data);
        return;
      } catch (error: any) {
        this.removeTokens();
        throw new Error(error.message);
      }
    }
  },

  /**
   * returns is_refreshAuthToken_locked status
   * @returns {boolean}
   */
  getLockStatus() {
    let lockStatus = localStorage.getItem(is_refreshAuthToken_locked);

    if (!lockStatus) {
      return;
    }

    lockStatus = JSON.parse(lockStatus);
    return lockStatus;
  },

  /**
   * Stores tokens inside the localstorage
   * @param token
   */
  storeTokens(token: iLoginToken) {
    const expiry = this.parsePayload(token.access_token).exp;
    localStorage.setItem(access_token, token.access_token);
    localStorage.setItem(refresh_token, token.refresh_token);
    localStorage.setItem(expires_in, `${expiry}`);
    localStorage.setItem(token_type, token.token_type);
    localStorage.setItem(is_refreshAuthToken_locked, "false");
  },

  /**
   * returns token from the local storage.
   * @returns
   */
  getTokens() {
    const tokens: any = {
      access_token: localStorage.getItem(access_token),
      refresh_token: localStorage.getItem(refresh_token),
      expires_in: localStorage.getItem(expires_in),
      token_type: localStorage.getItem(token_type),
    };
    return tokens;
  },

  /**
   * removes token from the local storage.
   * @returns
   */
  removeTokens() {
    localStorage.removeItem(access_token);
    localStorage.removeItem(refresh_token);
    localStorage.removeItem(expires_in);
    localStorage.removeItem(token_type);
    localStorage.removeItem(is_refreshAuthToken_locked);
  },

  /**
   * validates access token from the local storage.
   * @returns
   */
  validateToken() {
    const currentTimestamp = Math.floor(Date.now() / 1000);
    const exp = localStorage.getItem(expires_in);
    if (exp && currentTimestamp < parseInt(exp)) {
      return "valid";
    }
    return "expired";
  },

  /**
   * waits for specified number of sec when called
   * @param {Number}
   */
  sleep(ms: number) {
    return new Promise(resolve => {
      setTimeout(resolve, ms);
    });
  },

  async signUp(details: SignUpArgs): Promise<boolean> {
    const mappedDetails: signUpAPIArgs = {
      contactData: {
        first_name: details.firstName,
        last_name: details.lastName,
        email: details.email,
        company_name: details.companyName,
        industry: details.industry || "Other",
        organisation_size: details.organisationSize || "",
        contact_type: details.isBroker ? "Broker" : "Customer",
        has_consent: details.has_consent,
        phone: details.phoneNumber || "",
        password: details.password || "",
        invitation_id: details.invitation_id || "",
        policy: details.policy || "",
        isExistingAuth0User: details.isExistingAuth0User || false,
      },
    };

    try {
      const result = await axios.post("/users", mappedDetails);

      recordAnalyticsEvent("identify", "Signed Up", {
        firstname: details.firstName,
        lastname: details.lastName,
        email: details.email,
      });
      recordAnalyticsEvent("action", "Signed Up", {
        type: details.isBroker ? "broker" : "customer",
        invitation_id: details.invitation_id || "",
      });

      this.storeTokens(result.data);
      return result?.data ? true : false;
    } catch (err: any) {
      if (err.response && err.response.status === 409) {
        throw new UserAlreadyExistsError("Account already exists.");
      }
      if (
        err.response &&
        err.response.status >= 500 &&
        err.response.status < 600
      ) {
        throw new Error(
          `A server error occurred. Please try again later and contact support at ${process.env.REACT_APP_CARE_EMAIL_SUPPORT} would the error persist.`,
        );
      }
      if (details.isExistingAuth0User) {
        throw new Error(
          "An error occurred while linking your SafetyCulture account to Care. Please try again later.",
        );
      }
      throw new Error("An error occurred in sign up. Please try again later.");
    }
  },

  async updateUser(
    user: User,
    newDetails: { firstName: string; lastName: string; phone: string },
  ) {
    return axios({
      method: "patch",
      url: `/users/${user?.id}`,
      data: {
        firstname: newDetails.firstName,
        lastname: newDetails.lastName,
        phone: newDetails.phone,
        id: user?.id,
      },
    });
  },

  async emailExist(email: string) {
    try {
      const result = await axios({
        method: "get",
        url: "/users/check_for_email",
        params: {
          email: email,
        },
      });
      return result;
    } catch (error) {
      return false;
    }
  },

  isLoggedIn(): boolean {
    try {
      this.getCurrentSession();
      return true;
    } catch {
      return false;
    }
  },

  authorize({
    url,
    location,
    client_id,
    response_type,
    scope,
  }: iAuthorizeConfig) {
    const state = JSON.stringify({
      location,
    });
    const id = uuidv4();

    const key = `${id}`;

    window.sessionStorage.setItem(key, state);

    const redirect_uri = `${window.location.origin}/authorize/login`;

    window.location.assign(
      `${url}?response_type=${response_type}&client_id=${client_id}&state=${key}&redirect_uri=${redirect_uri}&scope=${scope}`,
    );
  },

  async verify(code: string, nonce: string) {
    const value = window.sessionStorage.getItem(nonce);
    window.sessionStorage.removeItem(nonce);

    if (!value || value.length === 0) {
      throw new LoginNotAuthorizedError();
    }

    const state = JSON.parse(value);

    const result = await axios.post("/auth/login", {
      code: code,
    });

    if (result && result?.data) {
      this.storeTokens(result.data);
    }

    return {
      message: "Logged in successfully",
      destination: state.location,
    };
  },

  parsePayload(token) {
    let base64Url = token.split(".")[1];
    let base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    let jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(""),
    );
    return JSON.parse(jsonPayload);
  },

  async sendConfirmationEmail(email: string): Promise<boolean | undefined> {
    try {
      const result: boolean = await axios.post(
        "/users/email/send-confirmation",
        {
          email: email,
        },
      );
      toastEvent.success(`Confirmation email sent to ${email}`);
      return result as boolean;
    } catch (err: any) {
      toastEvent.error("Uh oh. Something went wrong. Please try again later");
      if (err.response && err.response.status === 404) {
        throw new EmailNotFoundError("Please ensure your email is correct.");
      }
    }
  },

  async requestPasswordReset(
    input: Record<"email", string>,
    toast?: boolean,
  ): Promise<string | undefined> {
    try {
      const result = await axios.post("/auth/password/reset", {
        email: input.email,
      });
      if (toast) {
        toastEvent.success(`Password reset started for ${input.email}`);
      }
      return result?.data;
    } catch (err: any) {
      if (err.response && err.response.status === 404) {
        throw new EmailNotFoundError("Please ensure your email is correct.");
      }
    }
  },

  async changePassword(newPasswordInfo: iPasswordChange): Promise<any> {
    if (
      newPasswordInfo.passwordConfirm &&
      newPasswordInfo.password !== newPasswordInfo.passwordConfirm
    ) {
      throw new PasswordConfirmationInvalidError(
        "Please ensure the password and the confirmation are the same.",
      );
    }

    let result: any;
    try {
      result = await axios.post("/auth/password/change", {
        password: newPasswordInfo.password,
        token: newPasswordInfo.token,
      });
      return result;
    } catch (err: any) {
      if (err.response && err.response.status === 400) {
        throw new PasswordResetNotValid(
          "This link has expired, please try generating a new link on the reset password page.",
        );
      }
    }
  },
};

export default AuthService;

const access_token = "user_access_token";
const refresh_token = "user_refresh_token";
const expires_in = "user_access_token_expires_in";
const token_type = "token_type";
const is_refreshAuthToken_locked = "is_refreshAuthToken_locked";

export interface iLoginToken {
  access_token: string;
  refresh_token: string;
  expires_in: number;
  token_type: string;
}

export interface iAuthorizeConfig {
  url: string;
  location: string;
  client_id: string;
  response_type: string;
  scope: string;
}

export interface iPasswordChange {
  password: string;
  newPassword: string;
  passwordConfirm?: string;
  token: string;
}

export enum organisationSize {
  extraSmall = "1-4",
  small = "5-19",
  medium = "20-49",
  large = "50-199",
  extraLarge = "200+",
}

export enum industry {
  CONSTRUCTION = "Construction",
  MANUFACTURING = "Manufacturing",
  RETAIL = "Retail",
  HOSPITALITY = "Hospitality",
  OTHER = "Other",
}

export enum ChallengeEnum {
  NEW_PASSWORD_REQUIRED = "NEW_PASSWORD_REQUIRED",
}

export interface addressProps {
  address_1: string;
  address_2: string;
  city: string;
  state: string;
  post_code: string;
  country: string;
}

export interface SignUpArgs {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  industry: Industries | "";
  has_consent: boolean;
  phoneNumber: string;
  isBroker: boolean;
  companyName: string;
  organisationSize: OrganisationSizes | "";
  invitation_id?: string;
  policy?: string;
  isExistingAuth0User?: boolean;
}

export interface signUpMappedProps {
  email: string;
  password: string;
  first_name: string;
  last_name: string;
  has_consent: boolean;
  phone?: string;
  contact_type: ContactType | "";
  company_name: string;
  industry: Industries | "";
  organisation_size: OrganisationSizes | "";
  invitation_id?: string;
  policy?: string;
  isExistingAuth0User?: boolean;
}

export interface signUpAPIArgs {
  contactData: signUpMappedProps;
}

export interface ExternalMapping {
  id: string;
  name: string;
  external_id: string;
  related_model: string;
  related_id: string;
}

export type ContactType = "Broker" | "Customer";
export type Industries =
  | "Construction"
  | "Manufacturing"
  | "Retail"
  | "Hospitality"
  | "Other";
export type OrganisationSizes = "1-4" | "5-19" | "20-49" | "50-199" | "200+";

export interface User extends addressProps {
  activity?: Activity[];
  created_at: string;
  crm_id: string;
  email: string;
  firstname: string;
  id: string;
  is_active: boolean;
  is_marketable: boolean;
  lastname: string;
  organisations: IOrganisation[];
  externalMappings?: ExternalMapping[];
  phone: string;
  profile_picture_url?: string;
  roles: tUserRole[];
  type: string;
  updated_at: string;
}
