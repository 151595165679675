import { useQuery } from "react-query";
import { useLocation, useParams } from "react-router-dom";
import { Hidden } from "@material-ui/core";
import { IUser } from "../../../services/admin/admin.service";
import authService from "../../../services/auth/auth.service";
import { commaSeparated, formatDateTime } from "../../../helpers";
import {
  ActivityTable,
  RecordMissing,
  Summary,
} from "../../../components/content";
import { Header } from "../../../components/layout";
import {
  CircularProgress,
  Icon,
  Popover,
  Grid,
  Tab,
  TabPanel,
} from "../../../components/util";

import userService from "../../../services/user/user.service";
import * as Styled from "./styles.css";
import General from "./generel";

const UserDetails = () => {
  const location = useLocation();
  const { id } = useParams<IParams>();
  const userFetchCmd: string = "admin-user-details";
  const {
    isLoading,
    isError,
    data: user,
  } = useQuery<IUser | null | undefined>([userFetchCmd, { id }], async () =>
    userService.getUserByID(id),
  );

  if (isLoading) {
    return <CircularProgress contained />;
  } else if (isError || user == null) {
    return (
      <RecordMissing
        icon="person"
        message="Please check the user id or navigate back to Users."
        type="user"
        back={{
          title: "Back to Users",
          path: `/users${location.search || ""}`,
        }}
      />
    );
  }

  const userName = commaSeparated([user.lastname, user.firstname]);

  const OverflowMenuButton = () => {
    return (
      <Popover
        alignment="end"
        trigger={
          <Styled.MoreOptionIconButton size="sm" variant="text">
            <Icon icon="kebab-horizontal" />
          </Styled.MoreOptionIconButton>
        }
      >
        <Styled.MoreOptionSelectMenu>
          <Styled.MoreOptionSelectOption
            onClick={() =>
              authService.requestPasswordReset({ email: user.email }, true)
            }
          >
            Reset password
          </Styled.MoreOptionSelectOption>
        </Styled.MoreOptionSelectMenu>
      </Popover>
    );
  };

  return (
    <Styled.Grid>
      <Grid container>
        <Header
          actions={<OverflowMenuButton />}
          title={userName}
          titleIcon="person"
          titleIconPosition="end"
          type="article"
          withLink={{
            path: `/users${location.search || ""}`,
            text: "All Users",
          }}
        >
          <Hidden xsDown>
            <Styled.SubHeader element="span" variant="heading">
              {user.email}
            </Styled.SubHeader>
          </Hidden>
        </Header>
        <Summary
          data={[
            {
              Created: formatDateTime(user.created_at || "", "verbose"),
            },
            {
              Updated: formatDateTime(user.updated_at || "", "verbose"),
            },
          ]}
        />
        <Styled.Tabs item xs={12}>
          <Styled.InnerTabs
            id="user-details"
            tabs={[
              <Tab id="general" label={"General"} />,
              <Tab id="activity" label={"Activity"} />,
            ]}
            scrolling
          >
            <TabPanel id="general">
              <General />
            </TabPanel>
            <Styled.STTabpanel id="activity" spaced>
              <ActivityTable user_id={id} />
            </Styled.STTabpanel>
          </Styled.InnerTabs>
        </Styled.Tabs>
      </Grid>
    </Styled.Grid>
  );
};

interface IParams {
  id: string; // uuid
}

export default UserDetails;
