import * as React from "react";
import { PaginatedResults } from "../../../components/content";
import automationService from "../../../services/automation/automation.service";
import { TableCell, TableRow, Typography } from "../../../components/util";
import { Link } from "../styles.css";
import { formatDateTime } from "../../../helpers";
import { Automation } from "../../../types/automations.type";
import { AutomationStatusChip } from "../AutomationStatusChip";

type Props = { searchTerm: string };

export const AutomationList: React.FC<Props> = ({ searchTerm }) => {
  const AutomationRows = (automations: Automation[]) => (
    <>
      {automations.map(automation => (
        <TableRow key={automation.id}>
          <TableCell expand={true}>
            <Link
              href={`/automations/${automation.id}${location.search || ""}`}
              router
              title={automation.name}
              styled={{ text: { case: "title" } }}
            >
              {automation.name}
            </Link>
          </TableCell>
          <TableCell>
            <Typography styled={{ text: { case: "title" } }}>
              {!!automation.toolkits?.length && automation.toolkits[0].code}
              {automation.toolkits &&
                automation.toolkits.length > 1 &&
                ` (+${automation.toolkits.length - 1} more)`}
            </Typography>
          </TableCell>
          <TableCell>
            <Typography styled={{ text: { case: "title" } }}>
              {automation.industries &&
                automation.industries.length > 0 &&
                automation.industries[0].name}
              {automation.industries &&
                automation.industries.length > 1 &&
                ` (+${automation.industries.length - 1} more)`}
            </Typography>
          </TableCell>
          <TableCell>
            {formatDateTime(
              `${
                automation.updated_at
                  ? automation.updated_at
                  : automation.created_at
              }`,
              "short",
            )}
          </TableCell>
          <TableCell>
            <AutomationStatusChip enabled={automation.enabled} />
          </TableCell>
        </TableRow>
      ))}
    </>
  );

  return (
    <>
      <PaginatedResults
        id="automations"
        searchTerm={searchTerm}
        searchService={automationService.searchAll}
        service={automationService.getAll}
        tableColumns={[
          { title: "Name", expand: true },
          { title: "Toolkits" },
          { title: "ANZSICs" },
          { title: "Updated", collapse: true },
          { title: "Status", collapse: true },
        ]}
        tableRows={AutomationRows}
        missing={{
          icon: "dependabot",
          type: "automation",
          message: "No Automations found",
        }}
      />
    </>
  );
};
