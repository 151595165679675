import { iLabelProps } from "../../components/content/label/label";
import { TPolicyType } from "../../types";
import { Typography } from "@safetyculture/sc-web-ui";

export type TPolicyTypeFormat = "short" | "default" | "full";

type iPolicyLabelProps = Omit<iLabelProps, "color" | "size"> & {
  type: TPolicyType;
  format?: TPolicyTypeFormat;
};

export const PolicyProductBadge = ({
  type,
  format = "default",
}: iPolicyLabelProps) => {
  return (
    <Typography variant="bodyMediumStrong">
      {policyProductType(type, format)}
    </Typography>
  );
};

const policyProductType = (
  type: TPolicyType,
  format: TPolicyTypeFormat,
): string => {
  if (format === "short") {
    switch (type) {
      case "cpx":
        return "CPX";
      case "bpk":
        return "BPK";
      case "wc":
        return "WC";
      default:
        return "Unkown";
    }
  } else if (format === "full") {
    switch (type) {
      case "cpx":
        return "Commercial Package (CPX)";
      case "bpk":
        return "Business Package (BPK)";
      case "wc":
        return "Worker's Compensation (WC)";
      default:
        return "Unknown Product (?)";
    }
  } else {
    switch (type) {
      case "cpx":
        return "Commercial Package";
      case "bpk":
        return "Business Package";
      case "wc":
        return "Worker's Compensation";
      default:
        return "Unknown Product";
    }
  }
};
