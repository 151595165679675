import { useParams } from "react-router-dom";
import { Hidden } from "@material-ui/core";
import claimService from "../../../services/claim/claim.service";
import { IClaimContact } from "../../../types";
import { formatDateTime } from "../../../helpers";
import { CircularProgress } from "../../../components/util";
import { RecordMissing, Summary } from "../../../components/content";
import { Header } from "../../../components/layout";
import * as Styled from "./styles.css";
import { useClaimQuery } from "../../../hooks/queries/useClaimQuery";
import { ClaimDetailsTabs } from "./ClaimDetailsTabs";
import { Spinner, Stack } from "@safetyculture/sc-web-ui";

export const ClaimDetails = () => {
  const { claimId } = useParams<ClaimDetailsPageUrlParams>();

  const claim_query = useClaimQuery(claimId);

  if (claim_query.isLoading) {
    return (
      <Stack
        direction="column"
        align="center"
        justify="center"
        style={{ minHeight: "90vh" }}
      >
        <Spinner />
      </Stack>
    );
  } else if (claim_query.isError || claim_query.data == null) {
    return (
      <RecordMissing
        icon="shield"
        message="Please check the claim number or navigate back to your claims."
        type="claim"
        back={{
          title: "Back to Claims",
          path: "/claims",
        }}
      />
    );
  }

  const claimFormData = claim_query.data?.versions[0].claim_data;
  let contact_data: IClaimContact = {
    contact_name: "",
    phone_number: "",
    email: "",
  };
  if (claimFormData?.create) {
    contact_data = claimService.extractContactData(claimFormData);
  }

  return (
    <>
      <Styled.Grid>
        <Header
          title={`#${claim_query.data?.claim_number}`}
          titleIcon="file-directory-fill"
          titleIconPosition="end"
          type="article"
          withLink={{
            path: `/claims${location.search || ""}`,
            text: "All Claims",
          }}
        >
          <Hidden xsDown>
            <Styled.SubHeader element="span" variant="heading">
              {
                claim_query.data?.versions[0].claim_data.create?.claim
                  ?.insured_name
              }
            </Styled.SubHeader>
          </Hidden>
        </Header>

        <Summary
          data={[
            /*{
              Status: <Chip>{"Review"}</Chip>, // hardcoded as it is now, showing status removes value from the app. Let's put this back once it is dynamic
            },*/
            {
              TYPE_OF_CLAIM:
                claim_query.data?.versions[0].claim_data.create?.issue
                  ?.claim_type,
            },
            {
              "Incident date": formatDateTime(
                claim_query.data?.versions[0].claim_data.details?.incident_date,
                "verbose",
              ),
            },
            {
              Last_Updated: formatDateTime(
                claim_query.data?.updated_at,
                "verbose",
              ),
            },
          ]}
        />
        <ClaimDetailsTabs claim_query={claim_query} />
      </Styled.Grid>
    </>
  );
};

export type ClaimDetailsPageUrlParams = {
  claimId: string;
};
