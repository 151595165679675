export enum Role {
  Admin = "admin",
  SuperAdmin = "super_admin",
  Customer = "customer",
  Broker = "broker",
  Unverified = "unverified",
}

export type tUserRole = tUserRolePublic | tUserRolePrivate;
export type tUserRolePrivate = "admin" | "super_admin";
export type tUserRolePublic = "customer" | "broker" | "unverified";

export type tUserRoleAllowed = tUserRole | "*";

export interface iRole {
  id: string;
  name: string;
}

export interface iUserRole {
  id: string;
  user_id: string;
  role_id: string;
  created_at?: string;
  updated_at?: string;
}

export type UserRole = {
  id: string;
  name: string;
  created_at: string;
  updated_at: string;
};

export type UserRolesResponse = UserRole[];
