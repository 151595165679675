import { useQuery } from "react-query";
import { useLocation, useParams } from "react-router-dom";
import queryString from "query-string";
import { Hidden } from "@material-ui/core";

import { RecordMissing, Summary } from "../../../components/content";
import { Header } from "../../../components/layout";
import { formatDateTime } from "../../../helpers";
import General from "./general";

import {
  CircularProgress,
  Icon,
  Popover,
  TabPanel,
  Tab,
} from "../../../components/util";

import InvitationService, {
  Invitation,
} from "../../../services/invitation/invitation.service";

import * as Styled from "../styles.css";
import { InviteStatusChip } from "../InvitationsList/InviteStatusChip";

interface Params {
  id: string; // uuid
}

const InvitationDetails = () => {
  const location = useLocation();
  const { id } = useParams<Params>();
  const query = queryString.parse(location.search);
  const backText = query?.backText as string;

  const {
    isLoading,
    isError,
    data: invitation,
  } = useQuery<Invitation | undefined>(
    ["admin-invitation-details", id],
    async () => InvitationService.fetchById(id),
  );

  if (isLoading) {
    return <CircularProgress contained />;
  } else if (isError || invitation == null) {
    return (
      <RecordMissing
        icon="mail"
        message="Please check the invitation ID or navigate back to Invitations."
        type="invitation"
        back={{
          title: "Back to Invitations",
          path: `/invitations${query.from && `?p=${query.from}`}`,
        }}
      />
    );
  }

  const OverflowMenuButton = () => {
    return (
      <Popover
        alignment="end"
        trigger={
          <Styled.MoreOptionIconButton size="sm" variant="text">
            <Icon icon="kebab-horizontal" />
          </Styled.MoreOptionIconButton>
        }
      >
        <Styled.MoreOptionSelectMenu>
          <Styled.MoreOptionSelectOption
            onClick={() => InvitationService.resendInvitation(invitation)}
          >
            Resend invitation email
          </Styled.MoreOptionSelectOption>

          <Styled.MoreOptionSelectOption
            onClick={() => InvitationService.cancelInvitation(invitation)}
          >
            Cancel invitation
          </Styled.MoreOptionSelectOption>
        </Styled.MoreOptionSelectMenu>
      </Popover>
    );
  };

  return (
    <Styled.Grid>
      <Header
        title={`To: ${invitation.org_name}`}
        titleIcon="mail"
        titleIconPosition="end"
        type="article"
        withLink={{
          path: `/invitations${location.search ? location.search : ""}`,
          text: "All Invitations",
        }}
        actions={<OverflowMenuButton />}
      >
        <Hidden xsDown>
          <Styled.SubHeader element="span" variant="heading">
            {invitation.email}
          </Styled.SubHeader>
        </Hidden>
      </Header>

      <Summary
        data={[
          { Status: <InviteStatusChip status={invitation.status} /> },
          {
            Created: formatDateTime(invitation.created_at || "", "long"),
          },
          {
            Updated: formatDateTime(invitation.updated_at || "", "long"),
          },
        ]}
      />

      <Styled.Tabs item xs={12}>
        <Styled.InnerTabs
          id="tabs-invitation-detail"
          tabs={[<Tab id="tab-general" label="General" />]}
          scrolling
        >
          <TabPanel id="tab-general">
            <General invitation={invitation} />
          </TabPanel>
        </Styled.InnerTabs>
      </Styled.Tabs>
    </Styled.Grid>
  );
};

export default InvitationDetails;
