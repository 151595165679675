import * as React from "react";
import { InvitationResponse } from "../../../services/invitation/invitation.service";
import { Typography } from "../../../components/util";
import * as Styled from "../styles.css";
import { tUserRole } from "../../../types";
import { hasAdminLevelPriviledge } from "../../../helpers";

interface Props {
  invitation: InvitationResponse;
  activeUserRoles: tUserRole[];
}

export const InviteLink: React.FC<Props> = ({
  invitation,
  activeUserRoles,
}) => {
  const invitation_cell_text = invitation.customer_org_name
    ? `${invitation.customer_org_name}`
    : ` ${invitation.firstname} ${invitation.lastname}`;

  const has_admin_privilege = hasAdminLevelPriviledge(activeUserRoles);

  return (
    <>
      {has_admin_privilege ? (
        <Styled.Link
          href={`/invitations/${invitation.id}${location.search || ""}`}
          router
          title={invitation_cell_text}
          styled={{ text: { case: "title" } }}
        >
          To: {invitation_cell_text}
        </Styled.Link>
      ) : (
        <Typography styled={{ text: { case: "title" } }}>
          To: {invitation_cell_text}
        </Typography>
      )}
    </>
  );
};
